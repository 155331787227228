
// Libraries
import * as React from 'react'

const ContentBlockOne = ({title, content}) => (
    <div className='content-block-one'>
        <div className='row'>
            <div className='content-block-one__left'>
                <h2>{title}</h2>
            </div>
            <div className='content-block-one__right cms' dangerouslySetInnerHTML={{ __html: content }} />
        </div>
    </div>
)

export default ContentBlockOne

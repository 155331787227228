
// Libraries
import React, {createRef, useState} from 'react'
import { navigate } from 'gatsby'

// Animations
import { Player } from '@lottiefiles/react-lottie-player'
import buttonJson from '../animations/buttonDonate.json'

const HeroOne = ({heading, anchorLink}) => {
	let buttonLottiePlayer = createRef()
	const [buttonLottie, setButtonLottie] = useState(null)

	const buttonLottieHover = () => {
		buttonLottie.playSegments([[0, 18], [19, 20]], true)
	}

	const buttonLottieBlur = () => {
		buttonLottie.playSegments([[20, 2], [1, 0]], false)
	}

	const buttonLottieClick = () => {
		buttonLottie.playSegments([22, 112], true)
		setTimeout(() => {
			navigate('https://link.justgiving.com/v1/charity/donate/charityId/3135530?currency=GBP')
		}, 3000)
	}

	return (
		<header className='hero-one'>
			<div className='hero-one__container'>
				<h1 className='hero-one__text'>{heading}</h1>
				<button
					className='btn btn--lottie-wrap'
					onMouseEnter={buttonLottieHover}
					onMouseLeave={buttonLottieBlur}
					onClick={buttonLottieClick}>
					<span className='sr-only'>Donate</span>
					<Player
						src={buttonJson}
						ref={buttonLottiePlayer}
						lottieRef={(instance) => {
							setButtonLottie(instance)
						}}
						autoplay={false}
						loop={false} />
				</button>
			</div>

			{anchorLink}
		</header>
	)
}

export default HeroOne

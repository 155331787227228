
// Libraries
import React, {useRef} from 'react'
import {graphql} from 'gatsby'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import HeroOne from '../components/heroOne'
import ContentBlockOne from '../components/contentBlockOne'
import ContentBlockTwo from '../components/contentBlockTwo'
import AnchorButton from '../components/anchorButton'

const DonatePage = ({data}) => {

	const pageContent = data?.contentManagement?.content

	const headerIntroduction = pageContent?.header_introduction ? pageContent?.header_introduction : 'HELP US PROMOTE THE MENTAL HEALTH AND WELLBEING OF NHS WORKERS AND PAID CARERS ACROSS THE UK';
	
	const whyTitle = pageContent?.why_title ? pageContent?.why_title : 'WE DO THIS BECAUSE WE WANT TO';
	const whyContent = pageContent?.why_content ? pageContent?.why_content : 
		<>
			<h3>RAISE AWARENESS</h3>
			<p>of mental health and wellbeing, particularly of those who look after us</p>
			<h3>WIDEN ACCESS</h3>
			<p>across our beneficiaries so that background or financial circumstance is not a barrier to participation</p>
			<h3>SUPPORT THEIR HEALTH AND WELLBEING</h3>
			<p>through the experience of shared live music and supporting access to mental health and wellbeing services</p>
		</>
	
	const howTitle = pageContent?.how_title ? pageContent?.how_title : 'WE DO IT BY';
	const howStep1Title = pageContent?.how_step1_title ? pageContent?.how_step1_title : 'BUILDING A PURPOSE-BUILT REGISTRATION AND BALLOTING SYSTEM THAT IS:';
	const howStep2Title = pageContent?.how_step2_title ? pageContent?.how_step2_title : 'CREATING AN ORGANIZATION COMMITTED TO:';
	const howStep3Title = pageContent?.how_step3_title ? pageContent?.how_step3_title : 'FOCUSING ON PARTNERS AND BENEFICIARIES:';
	const howStep1Content = pageContent?.how_step1_content ? pageContent?.how_step1_content : 
		<>
			<ul>
				<li>scalable and flexible</li>
				<li>secure and robust</li>
			</ul>
		</>
	const howStep2Content = pageContent?.how_step2_content ? pageContent?.how_step2_content : 
		<>
			<ul>
				<li>it's charitable purpose</li>
				<li>long term partnerships</li>
				<li>good governance</li>
			</ul>
		</>
	const howStep3Content = pageContent?.how_step3_content ? pageContent?.how_step3_content : 
		<>
			<ul>
				<li>the founders take no salary</li>
				<li>all profits stay in the organization</li>
				<li>profits are reinvested to provide more tickets and make grants</li>
			</ul>
		</>

	// scroll to components
	const contentBlockOneComponent = useRef(null)
	const scrollToContentBlockOne = () => contentBlockOneComponent.current.scrollIntoView()

	return (
		<Layout>
			<Seo title='Donate'/>
			<section className='donate-page'>
				<div>
					<HeroOne 
						heading={headerIntroduction}
						anchorLink={<AnchorButton onClick={scrollToContentBlockOne}>Why we do this</AnchorButton>}
					/>
					<section ref={contentBlockOneComponent}>
						<ContentBlockOne title={whyTitle} content={whyContent}/>
					</section>
					<ContentBlockTwo title={howTitle} 
						stepOne={{title: howStep1Title, content: howStep1Content}} 
						stepTwo={{title: howStep2Title, content: howStep2Content}} 
						stepThree={{title: howStep3Title, content: howStep3Content}} 
					/>
				</div>
			</section>
		</Layout>
	)
}

export default DonatePage

export const query = graphql`
{
	contentManagement(slug: {eq: "donate"}) {
		id
		content {
			how_title
			why_title
			why_content
			how_step1_title
			how_step2_title
			how_step3_title
			how_step1_content
			how_step2_content
			how_step3_content
			header_introduction
		}
	}
}
`

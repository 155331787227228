
// Libraries
import React, {createRef, useState} from 'react'

// Components
import { StaticImage } from 'gatsby-plugin-image'

// Animations
import { Player } from '@lottiefiles/react-lottie-player'
import buttonJson from '../animations/buttonDonate.json'
import { navigate } from 'gatsby'

const ContentBlockTwo = ({title, stepOne, stepTwo, stepThree }) => {
	let buttonLottiePlayer = createRef()
	const [buttonLottie, setButtonLottie] = useState(null)

	const buttonLottieHover = () => {
		buttonLottie.playSegments([[0, 18], [19, 20]], true)
	}

	const buttonLottieBlur = () => {
		buttonLottie.playSegments([[20, 2], [1, 0]], false)
	}

	const buttonLottieClick = () => {
		buttonLottie.playSegments([22, 112], true)
		setTimeout(() => {
			navigate('https://link.justgiving.com/v1/charity/donate/charityId/3135530?currency=GBP')
		}, 3000)
	}

	return (
		<div className='content-block-two'>
			<div className='container'>
				<div className='row'>
					<div className='step-section'>
						<h2>{title}</h2>

						<div className='info-card'>
							<div className='info-card__number'>
								<StaticImage src='../img/text/one.svg' alt='ne' className='number' />
							</div>
							<div className='info-card__content'>
								<h3>{stepOne.title}</h3>
								<div dangerouslySetInnerHTML={{ __html: stepOne.content }} />
							</div>
						</div>

						<div className='info-card'>
							<div className='info-card__number'>
								<StaticImage src='../img/text/two.svg' alt='ne' className='number' />
							</div>
							<div className='info-card__content'>
								<h3>{stepTwo.title}</h3>
								<div dangerouslySetInnerHTML={{ __html: stepTwo.content }} />
							</div>
						</div>

						<div className='info-card'>
							<div className='info-card__number'>
								<StaticImage src='../img/text/three.svg' alt='ne' className='number' />
							</div>
							<div className='info-card__content'>
								<h3>{stepThree.title}</h3>
								<div dangerouslySetInnerHTML={{ __html: stepThree.content }} />
							</div>
						</div>

					</div>

					<div className='content-block-two__sub-content'>
						<div className='sub-container'>
							<h2>Help us make an impact. Even a small amount makes a difference. All profits are reinvested.</h2>
							<button
								className='btn btn--lottie-wrap'
								onMouseEnter={buttonLottieHover}
								onMouseLeave={buttonLottieBlur}
								onClick={buttonLottieClick}>
								<span className='sr-only'>Donate</span>
								<Player
									src={buttonJson}
									ref={buttonLottiePlayer}
									lottieRef={(instance) => {
										setButtonLottie(instance)
									}}
									autoplay={false}
									loop={false} />
							</button>
						</div>
					</div>

				</div>
			</div>

		</div>
	)
}

export default ContentBlockTwo
